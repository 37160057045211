//=======================| App Configuration |========================//
export default {
	appUrlBackEnd: 'https://demoapi.sislii.com',
	// appUrlBackEnd: 'http://sisliiresto.test',

	appLogo: '/static/images/logo.svg',
	appLogoDark: '/static/images/logo-dark.png',                        // App Logo,
	brand: 'Pollos y Parrillas "El gran Chef"',                                        		   // Brand Name
	copyrightText: '© Todos los Derechos Reservados | Hecho por',     // Copyright Text
	//theme color
	color: {
      	primary: '#1b2831',
      	accent : '#E21E42',
	}
}
